* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
  /* font-family: 'Merriweather', sans-serif; */
}

html {
  --color-gray-dark: rgb(40, 40, 40);
  --color-gray-dark-light: rgb(120, 120, 120);
  --color-gray-light: rgb(240, 240, 240);
  --color-gray-lighter: rgb(248, 248, 248);
  --color-white: rgb(255, 255, 255);
  --color-green: rgb(70, 150, 60);
  --color-gray-darker: rgb(24, 24, 24);
  --color-gray: rgb(190, 190, 190);
  --color-gray-darker-transparent: rgba(24, 24, 24, 0.7);
  --color-transparent: rgba(0, 0, 0, 0);
}

/* encode-sans-expanded-100 - latin */
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/encode-sans-expanded-v5-latin-100.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/encode-sans-expanded-v5-latin-100.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/encode-sans-expanded-v5-latin-100.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-100.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-100.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/encode-sans-expanded-v5-latin-100.svg#EncodeSansExpanded')
      format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-300 - latin */
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/encode-sans-expanded-v5-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/encode-sans-expanded-v5-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/encode-sans-expanded-v5-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-300.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/encode-sans-expanded-v5-latin-300.svg#EncodeSansExpanded')
      format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-200 - latin */
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/encode-sans-expanded-v5-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/encode-sans-expanded-v5-latin-200.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/encode-sans-expanded-v5-latin-200.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-200.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-200.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/encode-sans-expanded-v5-latin-200.svg#EncodeSansExpanded')
      format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-regular - latin */
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/encode-sans-expanded-v5-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/encode-sans-expanded-v5-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./assets/fonts/encode-sans-expanded-v5-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/encode-sans-expanded-v5-latin-regular.svg#EncodeSansExpanded')
      format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-500 - latin */
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/encode-sans-expanded-v5-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/encode-sans-expanded-v5-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/encode-sans-expanded-v5-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-500.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/encode-sans-expanded-v5-latin-500.svg#EncodeSansExpanded')
      format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-700 - latin */
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/encode-sans-expanded-v5-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/encode-sans-expanded-v5-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/encode-sans-expanded-v5-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/encode-sans-expanded-v5-latin-700.svg#EncodeSansExpanded')
      format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-600 - latin */
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/encode-sans-expanded-v5-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/encode-sans-expanded-v5-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/encode-sans-expanded-v5-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-600.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/encode-sans-expanded-v5-latin-600.svg#EncodeSansExpanded')
      format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-800 - latin */
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/encode-sans-expanded-v5-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/encode-sans-expanded-v5-latin-800.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/encode-sans-expanded-v5-latin-800.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-800.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/encode-sans-expanded-v5-latin-800.svg#EncodeSansExpanded')
      format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-900 - latin */
@font-face {
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/encode-sans-expanded-v5-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./assets/fonts/encode-sans-expanded-v5-latin-900.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./assets/fonts/encode-sans-expanded-v5-latin-900.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-900.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/encode-sans-expanded-v5-latin-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/encode-sans-expanded-v5-latin-900.svg#EncodeSansExpanded')
      format('svg'); /* Legacy iOS */
}
